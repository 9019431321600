import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import Rocket from '../img/rocket_computer.png';
import Allustra from '../img/allustra_img.png';
import Decoration from '../img/decoration.png';
import Decoration2 from '../img/decoration2.png'
import pivot from '../img/pivot.png';
import Phone from '../img/making_app.png'
import Robot from '../img/robot_laptop.png';
import AllustraSche from '../img/pivot_sche.png'
import Aliens from '../img/computer_aliens.png'
import CaribouIce from '../img/caribou.PNG';
import ReactLogo from '../img/reactLogo.png'
import jsLogo from '../img/jsLogo.png'
import javaLogo from '../img/javaLogo.png'
import ReCAPTCHA from "react-google-recaptcha"
import pythonLogo from '../img/pythonLogo.png'
import cssLogo from '../img/cssLogo.png'
import Game from '../img/aldo_game.PNG'
import { ReactComponent as Waves2 } from '../img/waves2.svg';
import csharp from '../img/csharpLogo.png';
import nighSky from '../img/nigh.png';
import codeSky from '../img/brain-space.png';
import canadaFlag from '../img/canada.png';
import franceFlag from '../img/france.png';
import { ReactComponent as Waves } from '../img/wave.svg';
import { ReactComponent as Mail } from '../img/mail_icon.svg';
import messageImg from '../img/message_illustration.png';
import paperPlane from '../img/paper_plane.png'
import MessageModal from '../Components/MessageModal';
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';
import Footer from '../Components/Footer'

function Home({ t, toggleLang }) {

    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [navActive, setNavActive] = useState(false);
    const [isContactVisible, setIsCotnactVisible] = useState(true);
    const [frenchActive, setFrenchActive] = useState(localStorage.getItem('aldo-language') === "fr");
    const projectsRef = useRef(null);
    const skillsRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

    const handleClickOpen = () => {
        setOpenConfirmDialog(true);
    };

    const handleClose = () => {
        setOpenConfirmDialog(false);
    };

    const sendMessage = async (msg) => {
        setLoading(true);
        try {
            await axios.post("https://us-central1-allustra-ef4fa.cloudfunctions.net/api/sendMail", msg);
            setOpenConfirmDialog(true)
            setLoading(false);
            setErrors(false);
        } catch (error) {
            setOpenConfirmDialog(true);
            setLoading(false);
            setErrors(true)
        }

    }
    const executeScroll = (ref, contact = false) => {
        if (contact) {
            setIsCotnactVisible(true);
        }
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }
    //homeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })\

    const contactVisible = (isVisible) => {
        setIsCotnactVisible(isVisible);
    }

    const changeLanguage = (language) => {
        localStorage.setItem('aldo-language', language);
        toggleLang(language)
        console.log('change language')
        language === "fr" ? setFrenchActive(true) : setFrenchActive(false);
    }

    return (
        <div className="home">
            <div className={navActive ? "hamburger active" : "hamburger"} onClick={() => setNavActive(!navActive)}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <nav className="nav" style={{ transform: navActive && "translate(0)" }}>
                <p className="nav-name">Aldo.</p>
                <div className="nav-options">
                    <p className="nav-link" onClick={() => executeScroll(projectsRef)}>{t("navegation.projects")}</p>
                    <p className="nav-link" onClick={() => executeScroll(skillsRef)}>{t("navegation.skills")}</p>
                    <p className="nav-link" onClick={() => executeScroll(aboutRef)}>{t("navegation.about")}</p>
                    <p className="nav-link" onClick={() => executeScroll(contactRef, true)}>{t("navegation.contact")}</p>
                    <img className="flag" src={canadaFlag} onClick={() => changeLanguage('en')} />
                    <img className="flag" src={franceFlag} onClick={() => changeLanguage('fr')} />
                </div>
            </nav>
            <Hero t={t} executeScroll={executeScroll} contactRef={contactRef} isContactVisible={isContactVisible} />
            <Waves className="waves" />
            <div ref={projectsRef}>
                <Experience t={t} />
            </div>
            <Waves2 className="waves" />
            <div ref={skillsRef}>
                <Skills t={t} id='skills' />
            </div>
            <div ref={aboutRef}>
                <About t={t} id='about' />

            </div>
            <MessageModal
                errors={errors} open={openConfirmDialog}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose} t={t} />

            <div ref={contactRef}>
                <VisibilitySensor onChange={contactVisible}>
                    <Contact t={t} id='contact' loading={loading} sendMessage={sendMessage} frenchActive={frenchActive} />

                </VisibilitySensor>
            </div>
            <Footer />
        </div>
    )
}

function Hero({ t, executeScroll, contactRef, isContactVisible }) {

    const [btnVisible, setBtnVisible] = useState();
    useEffect(() => {
        setBtnVisible(true)
    }, [])

    const btnVisibilityHandler = (isVisible) => {
        setBtnVisible(isVisible);
    }

    return (
        <div className="home-hero">
            <div className="hero-body">
                <div className="hero-text">
                    <h1>{t("hero.gretting")}</h1>
                    <h2 className="hero-text-title" >{t("hero.title")}</h2>
                    <div className="hero-text-subtitle-container">
                        <p className="hero-text-subtitle">Web </p>
                        <p className="hero-text-subtitle">Mobile </p>
                        <p className="hero-text-subtitle">Full Stack </p>

                    </div>
                </div>
                <VisibilitySensor onChange={btnVisibilityHandler}>
                    <div className={"hero-btn-container"}>
                        <p className="hero-btn-anchor">.</p>
                        <button style={{ visibility: !isContactVisible ? 'visible' : 'hidden' }} className={btnVisible ? "hero-btn btn-start" : "hero-btn btn-floating"} onClick={() => executeScroll(contactRef, true)}>
                            {btnVisible && <span className="hero-btn-text">{t("contact.title")}</span>}
                            {!btnVisible && <Mail className="mail-icon" />}
                        </button>
                    </div>
                </VisibilitySensor>
                <img className="hero-img" src={Rocket} alt="rocket" />
            </div>
        </div>
    )
}


function Experience({ t }) {


    return (
        <div className="main-grid">
            <div className="body-title-container">
                <h2 className="body-title">{t("projects.title")}</h2>
            </div>
            <div className="card-container">
                <img className="body-decoration" src={Decoration} alt=":)" />
                <img className="body-decoration2" src={Decoration2} alt=":)" />
                <div className="body-card-img-container">
                    <img className="body-card-img" src={Aliens} alt=":)" />

                    {/* <img className="body-img-robot" src={Robot} alt=":)" /> */}
                </div>

                <Card title={"Allustra"}
                    body={t("projects.allustraText")}
                    extra={"MERN Stack, Redux, React, Node.js, SCSS"}
                    img={Allustra}
                    url={"https://allustra-ef4fa.web.app/"}
                    large={false}
                />
                <Card title={"Pivot"}
                    body={t("projects.pivotText")}
                    extra={"MERN Stack, Redux, React, Node.js, SCSS"}
                    img={AllustraSche}
                    url={"https://pivot.jobs/"}
                    large={false}
                />
                <div className="body-card-img-container">
                    <img className="body-card-img-pivot" src={pivot} alt=":)" />
                    <img className="body-img-robot" src={Robot} alt=":)" />
                </div>

                <div className="body-card-img-container">
                    <img className="body-card-img-cellphone" style={{ opacity: '.68' }} src={Phone} alt=":)" />
                </div>

                <Card title={"Caribou App"}
                    body={t("projects.caribouText")}
                    extra={"Java, Android Studio"}
                    img={CaribouIce}
                    url={"https://www.caribou.ca/"}
                    large={true}
                />

                <Card title={"Star Game"}
                    body={t("projects.gameText")}
                    extra={"Javascript, HTML, CSS."}
                    img={Game}
                    url={"https://pfhm2.github.io/"}
                    large={false}
                />
            </div>
        </div>
    )
}

function Card(props) {
    const { t, title, body, extra, img, url, large } = props;


    const openProjectHandler = (theUrl) => {
        window.open(theUrl);
    }

    return (
        <div className="card" onClick={() => openProjectHandler(url)}>
            <div className="card-img-container">
                <img className="card-img" src={img} alt="allustra" />
            </div>
            <div className="card-body">
                <h3 className="card-title">{title}</h3>
                <p className="card-body-text">
                    {body}
                </p>
                <p className="card-body-extra">
                    {extra}
                </p>
            </div>
        </div>
    )
}

function Skills({ t }) {
    return (
        <div className="skills-container">
            <div className="body-title-container">
                <h2 className="body-title title-light">{t("skills.title")}</h2>
            </div>
            <div className="skills-wrapper">
                <div className="skill-item-container">
                    <p className="skill-item">C#</p>
                    <p className="skill-item">Git</p>
                    <p className="skill-item">Java</p>
                    <p className="skill-item">Agile/Scrum</p>
                    <p className="skill-item">React</p>
                    <p className="skill-item">JavaScript</p>
                    <p className="skill-item">Python</p>
                    <p className="skill-item">Node.js</p>
                    <p className="skill-item">MySQL</p>
                    <p className="skill-item">PHP</p>
                </div>
                <div className="skills-images-container">
                    <img className="logo-icon" src={ReactLogo} alt="logo" />
                    <img className="logo-icon" src={jsLogo} alt="logo" />
                    <img className="logo-icon" src={javaLogo} alt="logo" />
                    <img className="logo-icon" src={csharp} alt="logo" />
                    <img className="logo-icon" src={cssLogo} alt="logo" />
                    <img className="logo-icon" src={pythonLogo} alt="logo" />
                </div>
            </div>
        </div>
    )
}

function About({ t }) {
    return (
        <div className="about-container" >

            <div className='about-info' >
                <div className="body-title-container">
                    <h2 className="body-title title-light">{t("about.title")}</h2>
                </div>
                <img className="background-image" src={nighSky} />
                <img className="img-code" src={codeSky} />
                <div className="about-text-container">
                    <h3>{t("about.education")}</h3>
                    <p>{t("about.educationText")}</p>
                    <h3>{t("about.experience")}</h3>
                    <p>{t("about.experienceText")}</p>
                    <p>{t("about.experienceText2")}</p>
                    <h3>{t("about.about")}</h3>
                    <p>{t("about.aboutText")}</p>
                </div>
            </div>
        </div>
    )
}

function ReCaptchaComponent({ language, reRef, onChange }) {
    useEffect(() => {
        console.log("language is ", language);
        return () => {
            console.log('un mounting recaptch')
        }
    }, [])
    return (
        <ReCAPTCHA
            ref={reRef}
            hl={language}
            sitekey="6LeQs3gaAAAAAIw86TR6eji-nnMSc3S5rOKxE01m"
            onChange={onChange}
        />
    )
}

function Contact({ t, sendMessage, loading, frenchActive }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [allErrors, setAllErrors] = useState({});
    const reRef = useRef(null);
    const [token, setToken] = useState(false);
    const [isRecaptcha, setIsRecaptcha] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }
        let emailMessage = { name, email, subject, message }
        let errors = {};
        const errorMsg = "Must not be empty";
        for (const [key, value] of Object.entries(emailMessage)) {
            if (value.trim() === '') {
                errors[key] = errorMsg;
            }
        }
        emailMessage["token"] = token;

        if (Object.keys(errors).length > 0) {
            setAllErrors(errors)
            return;
        } else {
            setAllErrors({});

            if (!reRef.current.getValue()) {
                setIsRecaptcha(true);
                return;
            }
            await sendMessage(emailMessage);
            setToken("");
            reRef.current.reset()
            setName("");
            setEmail("");
            setSubject("");
            setMessage('');
        }

    }

    async function onChange(value) {
        setIsRecaptcha(false);
        setToken(value);
    }

    return (
        <div className="contact-container">
            <div className="contact-wrapper">
                <h2 className="contact-title title-light">{t("contact.title")}</h2>
                <div className="contact-inputs">
                    <label htmlFor="name">{t("contact.name")}</label>
                    <input id="name" className="contact-input" style={{ border: allErrors.name ? '1px red solid' : '' }} value={name} onChange={(e) => setName(e.target.value)} placeholder={t("contact.name")} type="text" required />
                    <label htmlFor="email">{t("contact.email")}</label>
                    <input id="email" className="contact-input" style={{ border: allErrors.email ? '1px red solid' : '' }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("contact.email")} type="text" required />
                    <label htmlFor="subject">{t("contact.subject")}</label>
                    <input id="subject" className="contact-input" style={{ border: allErrors.subject ? '1px red solid' : '' }} value={subject} onChange={(e) => setSubject(e.target.value)} placeholder={t("contact.subject")} type="text" required />
                    <label htmlFor="message">{t("contact.message")}</label>
                    <textarea id="message" className="contact-areatext" style={{ border: allErrors.message ? '1px red solid' : '' }} value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t("contact.message")} rows="4" required />
                    <div className="reCaptcha-container">

                        {frenchActive &&
                            <ReCaptchaComponent language={"fr"} reRef={reRef} onChange={onChange} />}
                        {!frenchActive &&
                            <ReCaptchaComponent language={"en"} reRef={reRef} onChange={onChange} />
                        }
                        {isRecaptcha && <p style={{ color: "red" }}>This box needs to be checked</p>}
                    </div>
                    <button type="submit" className="btn" onClick={submitHandler}>{loading ? t("loading") : t("contact.send")}</button>
                    <img className="contact-img" src={messageImg} />
                    <img className="contact-img2" src={paperPlane} />
                </div>
            </div>
        </div>
    )
}



export default withTranslation()(Home);

