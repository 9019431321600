import React, { useEffect } from "react";
import './App.css';
import Home from './Screens/Home';
import Something from "./Screens/Something"
import { useTranslation, I18nextProvider } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  const { t, i18n } = useTranslation();


  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (localStorage.getItem('aldo-language')) {
        i18n.changeLanguage(localStorage.getItem('aldo-language'));
      }


    }
    return () => {
      mounted = false;
    }
  }, []);


  const toggleLang = (option) => {
    switch (option) {
      case 'en':
        i18n.changeLanguage('en');
        break;
      case 'fr':
        i18n.changeLanguage('fr');
        break;
      default:
        i18n.changeLanguage('en');
        break;
    }
  }



  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Switch>

          <Route path="/something">
            <Something />
          </Route>
          <Route path="/">
            <Home toggleLang={toggleLang} />
          </Route>

        </Switch>
      </Router>
    </I18nextProvider>
  );
}


export default App;
