
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            lg: "en",
            loading: "Loading...",
            navegation: {
                projects: "Projects",
                skills: "Skills",
                about: "About",
                contact: "Contact"
            },

            hero: {
                gretting: "Hey it's Aldo",
                title: "Software Developer"
            },
            projects: {
                title: "Some Projects",
                allustraText: "It's a platform where artist can share their illustrations and interact with other artists, a personal project",
                pivotText: "Pivot manages schedules, payrolls and facilites communication within a company",
                caribouText: "Besides working on the website of Caribou I made an App that warns if the ice is too thin to fish, gives tips and calculates fish weight base on its measures.",
                gameText: "Game I did while at school, get as many stars as possible before the time is over"
            },
            skills: {
                title: "Skills"
            },
            about: {
                title: "About",
                education: "Education",
                educationText: "Graduated John Abbot College in Computer Science 2020",
                experience: "Experience",
                experienceText: "Caribou Inc, Front End Developer 2020 Stage",
                experienceText2: "Pivot, Full Stack Developer present",
                about: "About me",
                aboutText: "I'm based in Montreal, I like creating useful things with code and enjoy learning new technologies, I also like playing musical instruments."
            },
            contact: {
                title: "Contact",
                name: "Name",
                email: "Email",
                subject: "Subject",
                message: "Message",
                send: "Send",
                messageFail: "Message could not send",
                messageSuccess: "Message Sent",
                confirmationSuccess: "Thank you for your message, I will get back as soon as possible.",
                confirmationFail: "Please contact aldo by email, aldoemails@gmail.com"
            }
        }
    },
    fr: {
        translation: {
            lg: "fr",
            loading: "Chargement en cours...",
            navegation: {
                projects: "Projets",
                skills: "Compétences",
                about: "À propos",
                contact: "Contacter"
            },

            hero: {
                gretting: "Salut Je suis Aldo",
                title: "Développeur de logiciels"
            },
            projects: {
                title: "Quelques projets",
                allustraText: "C'est une plateforme où les artistes peuvent partager leurs illustrations et interagir avec d'autres artistes, c'est un projet personnel",
                pivotText: "Pivot gère les horaires et la paie et facilite la communication dans l'entreprise",
                caribouText: " j'ai créé une application qui avertit si la glace est trop mince pour pêcher, donne des conseils et calcule le poids du poisson en fonction de ses mesures.",
                gameText: "J'ai crée un jeu quand j'étais à l'école, il faut avoir autant d'étoiles que possible avant la fin du temps imparti."
            },
            skills: {
                title: "Compétences"
            },
            about: {
                title: "À propos",
                education: "Éducation",
                educationText: "Diplômé en sciences informatiques du John Abbot College 2020",
                experience: "Expérience professionelle",
                experienceText: "Caribou Inc, Développeur Front End Stage 2020",
                experienceText2: "Pivot, développeur Full Stack présent",
                about: "À propos de moi",
                aboutText: "Je suis basé à Montréal, j'aime créer des choses utiles avec du code et j'aime apprendre de nouvelles choses."
            },
            contact: {
                title: "Contact",
                name: "Nom",
                email: "Courriel",
                subject: "Objet",
                message: "Message",
                send: "Envoyer",
                messageFail: "Le message n'a pas pu être envoyé",
                messageSuccess: "Message envoyé",
                confirmationSuccess: "Merci pour votre message, je reviendrai dans les plus brefs délais.",
                confirmationFail: "Veuillez contacter Aldo par email, aldoemails@gmail.com"
            }
        }
    }

};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;