import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import img from '../img/paper_plane.png';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MessageModal(props) {
    const { t } = props;
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="dialog-message">
                    <DialogTitle id="alert-dialog-slide-title">{props.errors ? t("contact.messageFail") : t("contact.messageSuccess")}</DialogTitle>
                    <DialogContent className="dialog-message-content">
                        <img className="messageDialog-img" src={img} />
                        <DialogContentText id="alert-dialog-slide-description">
                            {props.errors ? t("contact.confirmationFail") : t("contact.confirmationSuccess")}
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
}

export default withTranslation()(MessageModal)
