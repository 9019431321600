import React, { useState, useRef, useEffect } from 'react'
import { withTranslation } from 'react-i18next';

function Something({ t, toggleLang }) {



    return (
        <div>
            <h2 style={{ margin: "0px auto", display: "block" }}>Hello something</h2>
            <iframe style={{ margin: "0px auto", display: "block" }} xml="lang" src="https://www.example.com/" width="650" height="300"></iframe>
        </div>
    )
}

export default withTranslation()(Something);

