import React from 'react'
import { ReactComponent as Linkedin } from '../img/linkedin.svg';
export default function Footer() {

    const handleClick = () => {
        window.open("https://www.linkedin.com/in/aldo-r-g/");
    }
    return (
        <div className="footer">
            <h2 className="footer-title">Aldo.</h2>
            <Linkedin className="linkedin-icon" onClick={handleClick} />
        </div>
    )
}
